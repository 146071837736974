import React from "react";
import Img from "gatsby-image";
import { Row, Col } from "react-styled-flexboxgrid";
import { graphql, StaticQuery, navigate } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { Primary, Alternate } from "../../components/Section";
import { Button, ButtonGhost } from "../../components/Button";
import HomeHero from "./HomeHero";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-buddah-warm-sunset.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 20%;
`;

const Home = ({ data }) => {
  return (
    <section>
      <HomeHero />
      <Primary centered>
        <Row>
          <Col xs={12}>
            <h2>MJ MD Global Management Group</h2>
            <p>
              Welcome to the MJ MD Global! We are a management and product
              development company whose primary mission is to{" "}
              <i>“Create New History!”</i> Through product innovation, fashion,
              surgical services, mediation and even horse racing, a new energy,
              and new history is created. Our ultimate theme is to “Go
              Touchless!”
            </p>
            <br />
            <Button primary onClick={() => navigate("/philosophy")}>
              Read More
            </Button>
          </Col>
        </Row>
      </Primary>
      <StyledBackgroundSection centered>
        <h1>Go Touchless!</h1>
      </StyledBackgroundSection>
      <Alternate>
        <Row>
          <Col xs={12} md={6}>
            <h2>Product Innovation</h2>
            <p>
              Product Innovation and management of new historical companies
              represent the cornerstone of the MJ MD Global Group. We embrace
              and invest in the mind, body, and spirit of companies that promote{" "}
              <bold>new history</bold> among individuals, couples, cultures and
              communities.
            </p>
            <ButtonGhost onClick={() => navigate("/touchless")}>
              Read More
            </ButtonGhost>
          </Col>
          <Col xs={12} md={6}>
            <Img
              fluid={data.mjbuilding.childImageSharp.fluid}
              alt="MJ Global Building"
            />
          </Col>
        </Row>
      </Alternate>
    </section>
  );
};

export default Home;

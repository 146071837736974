import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Home from "../components/Home";

const HomePage = ({ data }) => (
  <Layout>
    <Home data={data} />
  </Layout>
);

export default HomePage;

export const query = graphql`
  query {
    mjbuilding: file(relativePath: { eq: "mj-group-building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

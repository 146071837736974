import React from "react";

import styled from "styled-components";
import Slider from "react-slick";
import { Alternate } from "../Section";

import Logo from "../../images/logo.svg";
import { rem } from "polished";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const StyledLogo = styled.img`
  height: ${rem(315)};
  display: block;
  margin: 0 auto;
  border-bottom: 15px solid #fff;
  padding-bottom: ${rem(30)};
  margin-bottom: ${rem(30)};
`;

const StyledSlide = styled.div`
  position: relative;
  z-index: 100;
  h3 {
    font-size: ${rem(95)};
    text-align: center;
    font-family: "Open-Sans", sans-serif;
    font-weight: 500;
    letter-spacing: 13px;
  }
`;

const HomeHero = () => {
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    zIndex: 1000,
  };
  return (
    <Alternate>
      <StyledLogo src={Logo} alt="MJ MD logo" />
      <Slider {...settings}>
        <StyledSlide>
          <h3>Dr Michael J</h3>
        </StyledSlide>
        <StyledSlide>
          <h3>Global</h3>
        </StyledSlide>
        <StyledSlide>
          <h3>Hand</h3>
        </StyledSlide>
        <StyledSlide>
          <h3>Voice</h3>
        </StyledSlide>

        <StyledSlide>
          <h3>Coach</h3>
        </StyledSlide>
      </Slider>
    </Alternate>
  );
};

export default HomeHero;
